@import "../../css/mixins.scss";

.wrapper {
  height: 100%;
  width: 100%;
  .blured {
    @include absoluteFill;
    filter: blur(15px);
    z-index: -1;
  }
}

img {
  width: 100%;
  height: 100%;
}
