@import "./mixins.scss";

@mixin vars() {
  --scrollbar-width: 5px;
  @media (min-width: $tablet) {
    --scrollbar-width: 10px;
  }

  @media (min-width: $laptop) {
  }

  @media (min-width: $desktop) {
    --scrollbar-width: 15px;
  }
}

::-webkit-scrollbar {
  @include vars;

  width: var(--scrollbar-width);
}

::-webkit-scrollbar-thumb {
  background: var(--gold-grad);
}

::-webkit-scrollbar-track {
  background-color: var(--black);
}
