@import "../../../css/mixins.scss";

@mixin vars() {
  --text-lines: 3;
  @media (min-width: $tablet) {
    --text-lines: 5;
  }

  @media (min-width: $laptop) {
    --text-lines: 6;
  }

  @media (min-width: $desktop) {
  }
}

.wrapper {
  @include vars;
  @include flex;
  @include absoluteMiddle;
  @include blurEffect;

  width: 100%;
  height: 100%;
  z-index: 1;

  .container {
    @include flexColumn(flex-start, flex-end);

    height: 100%;
    width: var(--screen-width-small);
    padding-right: 0;
    padding-bottom: var(--pad-7);
    box-sizing: border-box;
    gap: var(--pad-3);

    @media (min-width: $tablet) {
      padding-bottom: 0;
      justify-content: center;
    }
    .text {
      @include flexColumn(flex-start);

      gap: var(--pad-1);
      z-index: 1;
      .description {
        @include paragraphMaxLines(var(--text-lines));
      }
    }
  }

  @media (min-width: $laptop) {
    &::before {
      right: 30%;
      backdrop-filter: blur(100px);
      mask: linear-gradient(90deg, black, transparent);
      background-color: rgba(0, 0, 0, 0.7);
    }
    .container {
      padding-right: calc(var(--screen-width-small) / 2);
    }
  }
}

.right {
  @media (min-width: $laptop) {
    &::before {
      right: 0;
      left: 30%;
      mask: linear-gradient(90deg, transparent, black);
    }
    .container {
      align-items: flex-end;
      padding-left: calc(var(--screen-width-large) / 2);
      padding-right: 0;
      .text {
        align-items: flex-end;
      }
    }
  }
}
